export class ClientJob {
  id: string;
  active: boolean;
  description: string;
  appId: string;
  roleId: string;
  //appClientJobId: string;
  selected: boolean;
  isEdit: boolean;

  constructor() {}
}
